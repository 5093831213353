
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default defineComponent({
  components: {
    ClipLoader
  },

  props: {
    text: {
      type: String
    },
    icon: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    disabledReason: {
      type: Object as PropType<"NO_ITEMS_LEFT" | "AFTER_DEADLINE" | null>
    },
    optClass: {
      type: String
    },
    tooltip: {
      type: Boolean
    },
    loadingState: {
      type: Boolean
    }
  },

  setup(props) {
    const { t } = useI18n();
    const getIconUrl = (): string => {
      return require("@/assets/icons/" + props.icon + ".svg");
    };

    return {
      t,
      getIconUrl
    };
  }
});
