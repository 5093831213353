
import { defineComponent, ref, Ref, inject, onMounted } from "vue";
import TheHeader from "@/components/TheHeader.vue";
import CustomAlert from "@/components/CustomAlert.vue";
import { useRouter } from "vue-router";

import { MarketConfig } from "@/types";

export default defineComponent({
  name: "app",
  components: {
    TheHeader,
    CustomAlert
  },

  setup() {
    const router = useRouter();
    const isAlertVisible = ref(false) as Ref<boolean>;
    const { favicon, title } = inject("marketConfig") as MarketConfig;
    const type = ref("") as Ref<string>;
    const rmaCode = ref("") as Ref<string>;

    const openAlert = () => (isAlertVisible.value = true);
    const closeAlert = () => {
      isAlertVisible.value = false;
    };
    const closeAlertAndRoute = () => {
      isAlertVisible.value = false;
      router.push("/login/order");
    };

    const emitter = inject("emitter") as any;
    emitter.on("showAlert", (e: any) => {
      type.value = e.type;
      rmaCode.value = e.code;
      openAlert();
    });

    const logInitMessage = () => {
      const version = process.env.VUE_APP_VERSION || "0";
      const appName = process.env.VUE_APP_NAME || "";
      const message = `%c ${appName} version ${version} %c`;
      console.info(message, "background-color: #2fdf84");
    };

    onMounted(() => {
      logInitMessage();
    });

    return {
      isAlertVisible,
      openAlert,
      closeAlert,
      type,
      rmaCode,
      closeAlertAndRoute,
      favicon,
      title
    };
  }
});
