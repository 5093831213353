export function invalidTexts(t: any): { getInvalidText: Function } {
  const getInvalidText = (errorType: string) => {
    switch (errorType) {
      case "required":
        return t("NEW_TICKET.INVALIDS.INVALID_EMPTY");
      case "alphaWhiteDiacritic":
        return t("NEW_TICKET.INVALIDS.INVALID_ALPHA");
      case "phone":
        return t("NEW_TICKET.INVALIDS.INVALID_NUMERIC");
      case "alphaNum":
        return t("NEW_TICKET.INVALIDS.INVALID_ALPHANUM");
      case "minLength":
        return t("NEW_TICKET.INVALIDS.INVALID_SHORT");
      case "email":
        return t("NEW_TICKET.INVALIDS.INVALID_EMAIL");
    }
  };

  return { getInvalidText };
}
