
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  Ref,
  ref,
  watch
} from "vue";
import { useI18n } from "vue-i18n";
import { OrderItem } from "@/types";

interface ProductLengthsData {
  hasClickedOnMore: Ref<boolean>;
  getAllProductsLength: ComputedRef<number>;
  productsToDisplay: ComputedRef<OrderItem[] | Ref<OrderItem[]>>;
  hasMorethanXProducts: ComputedRef<boolean>;
  getOrdersFirstXProducts: ComputedRef<OrderItem[] | Ref<OrderItem[]>>;
}

function productLengthsData(
  orderItems: Array<OrderItem>,
  noOfProductsToDisplay: number
): ProductLengthsData {
  const hasClickedOnMore = ref(false);

  const getOrdersFirstXProducts = computed(() =>
    orderItems.length > noOfProductsToDisplay
      ? orderItems.slice(0, noOfProductsToDisplay)
      : orderItems
  );

  const productsToDisplay = computed(() =>
    hasClickedOnMore.value ? orderItems : getOrdersFirstXProducts.value
  );

  const hasMorethanXProducts = computed(
    () => orderItems.length > noOfProductsToDisplay
  );

  watch(hasClickedOnMore, () => {
    const moreProductsElemenent = document.getElementsByClassName(
      "img-wrapper-more"
    )[0];
    moreProductsElemenent.remove();
  });

  return {
    hasClickedOnMore,
    productsToDisplay,
    getAllProductsLength: computed(() => orderItems.length),
    hasMorethanXProducts,
    getOrdersFirstXProducts
  };
}

export default defineComponent({
  props: {
    orderItems: {
      type: Object as PropType<Array<OrderItem>>,
      required: true
    }
  },
  setup(props) {
    const { t, locale: lang } = useI18n();
    const noOfProductsToDisplay = 3;

    const {
      getOrdersFirstXProducts,
      hasClickedOnMore,
      productsToDisplay,
      getAllProductsLength,
      hasMorethanXProducts
    } = productLengthsData(props.orderItems, noOfProductsToDisplay);
    return {
      t,
      lang,
      getOrdersFirstXProducts,
      hasClickedOnMore,
      productsToDisplay,
      getAllProductsLength,
      hasMorethanXProducts,
      noOfProductsToDisplay
    };
  }
});
