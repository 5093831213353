import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/close.svg'


const _hoisted_1 = ["alt", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    _createElementVNode("img", {
      src: _imports_0,
      class: _normalizeClass(["close", { round: _ctx.round }]),
      alt: _ctx.t('COMMON.CLOSE'),
      title: _ctx.t('COMMON.CLOSE'),
      "data-cy": "remove-photo"
    }, null, 10, _hoisted_1)
  ]))
}