
import { defineComponent, PropType, inject } from "vue";
import { useI18n } from "vue-i18n";
import { RmaCaseForOrder } from "@/types";
import { rmaCaseTypeImages, rmaCaseStateImages } from "@/constants";
import { getIconUrl, redirectTo } from "@/utils";
import { MarketConfig } from "@/types";

import Button from "@/components/Button.vue";

export default defineComponent({
  components: {
    Button
  },
  props: {
    rmas: {
      type: Object as PropType<Array<RmaCaseForOrder> | null>
    }
  },
  setup() {
    const { t, locale: lang } = useI18n();

    const { change } = inject("marketConfig") as MarketConfig;

    return {
      t,
      change,
      lang,
      tableHeaders: ["ORDER_NO", "TYPE", "STATE", "PRODUCTS", "EMPTY"],
      getIconUrl,
      rmaCaseTypeImages,
      rmaCaseStateImages,
      redirectTo
    };
  }
});
