import { router } from "@/router/router";
import { MyWindow, RmaCaseStateTimeline } from "@/types";
export * from "./get-invalid-texts";
export * from "./get-alert-data";

export function redirectTo(componentName: string, parameters?: any): void {
  router.push({ name: componentName, params: parameters });
}

export function getIconUrl(iconUrl: string): string {
  return require("@/assets/icons/" + iconUrl + ".svg");
}

export function isUrlParametersValid(query: any): boolean {
  return (
    Object.keys(query).length === 2 &&
    Object.keys(query).every(
      keyName =>
        keyName === "email" || keyName === "orderId" || keyName === "ticketId"
    )
  );
}

export function isCypress(): boolean {
  return (window as MyWindow).Cypress;
}

export function moveElement(
  element: HTMLElement,
  direction: "up" | "down",
  transitionDistance: number
): void {
  if (direction === "down")
    element.style.transform = `translateY(${transitionDistance}px)`;
  else element.style.transform = `translateY(${transitionDistance}px)`;
}

export function sidebarAnimation() {
  const sidebarElement = document.getElementById("sidebar")!;
  const childSidebar = document.getElementById("sidebar-card")!;

  const bottomMargin = 40;
  const transitionDistance =
    sidebarElement.offsetHeight - childSidebar.offsetHeight - bottomMargin;

  const scrolledToBottom = (): boolean =>
    window.innerHeight + window.scrollY >=
    (document.body.children[1] as HTMLElement).offsetHeight
      ? true
      : false;

  const mediaQueryToStartAnimFrom = window.matchMedia("(min-width: 860px)");

  if (mediaQueryToStartAnimFrom.matches) {
    if (scrolledToBottom()) {
      sidebarElement.classList.add("scrolledDown");
      moveElement(childSidebar, "down", transitionDistance);
    } else {
      sidebarElement.classList.remove("scrolledDown");
      moveElement(childSidebar, "up", 0);
    }
  }
}

export function removeWaitingReplyState(
  stateTimeline: ReadonlyArray<RmaCaseStateTimeline>
): ReadonlyArray<RmaCaseStateTimeline> {
  if (
    stateTimeline[3].label === "WAITING REPLY" &&
    stateTimeline[3].status === "actual"
  )
    stateTimeline[2].status = "actual";
  return stateTimeline.filter(
    (state: RmaCaseStateTimeline) => state.label !== "WAITING REPLY"
  );
}

export function replaceWaitingReplyState(state: string) {
  return state !== "waiting reply" ? state.toUpperCase() : "APPROVING";
}

export function focusOnIvalids() {
  setTimeout(() => {
    const scrollBehavior: any = isCypress()
      ? {
          block: "nearest"
        }
      : { block: "nearest", behavior: "smooth" };
    const invalidElement = document.getElementsByClassName(
      "invalid"
    )[0] as HTMLElement;
    invalidElement.scrollIntoView(scrollBehavior);
  }, 100);
}
