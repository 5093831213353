import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

export function initSentry(app: any) {
  Sentry.init({
    app,
    environment: process.env.NODE_ENV,
    release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    debug: process.env.NODE_ENV !== "production",
    tracesSampleRate: process.env.NODE_ENV === "production" ? 0.2 : 1
  });
}
