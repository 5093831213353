import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  NormalizedCacheObject
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { getAuthToken, logoutUser } from "./auth";
import { VueCookieNext } from "vue-cookie-next";

import { ApiKey } from "@/types";

export default function setApolloClient(
  apiUrl: string,
  apiKey: ApiKey
): ApolloClient<NormalizedCacheObject> {
  // HTTP connection to the API
  const httpLink = createUploadLink({
    uri: apiUrl + "/frontend-api/graphql/"
  });

  const authLink = setContext(async (_, { headers }) => {
    const authToken =
      VueCookieNext.getCookie("authToken") || (await getAuthToken(apiKey));

    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : null
      }
    };
  });

  const logoutLink = onError(({ networkError = {} as any }) => {
    const status: number =
      networkError && networkError.statusCode ? networkError.statusCode : null;
    if (status === 401) {
      logoutUser("logout", true);
    }
  });

  const link = ApolloLink.from([authLink, logoutLink, httpLink]);

  const defaultClient = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    connectToDevTools: true
  });

  return defaultClient;
}
