import {
  RmaCaseDetail,
  RmaCreateForm,
  RmaCaseTypeImages,
  RmaCaseStateImages,
  OrderDetail
} from "@/types";

export const defaultOrderData: OrderDetail = {
  token: "",
  result: false,
  reason: {
    id: 0,
    description: ""
  },
  order: {
    id: "",
    originId: "",
    datetimeCreated: "",
    state: "",
    price: {
      value: 0,
      currency: ""
    },
    customer: {
      name: "",
      email: "",
      phone: ""
    }
  },
  items: [],
  rmas: [],
  newRma: {
    valid: true,
    reasonId: 0,
    reason: ""
  }
};

export const defaultRmaDetail: RmaCaseDetail = {
  result: false,
  reason: {
    id: 0,
    description: ""
  },
  rmaCase: {
    id: "",
    type: "",
    state: "",
    datetimeCreated: "",
    claimLetterUrl: "",
    customer: {
      email: "",
      phone: "",
      name: ""
    },
    code: ""
  },
  items: [],
  stateTimeline: [],
  solution: {
    type: "",
    newOrder: "",
    iban: ""
  },
  messages: [],
  texts: {
    successMessage: "",
    nextSteps: ""
  },
  order: {
    id: "",
    originId: "",
    datetimeCreated: "",
    state: "",
    price: {
      value: 0,
      currency: ""
    },
    customer: {
      name: "",
      email: "",
      phone: ""
    }
  },
  uploadedFilesUrl: []
};

export const defaultRmaForm: RmaCreateForm = {
  result: false,
  reason: {
    id: 0,
    description: ""
  },
  order: {
    id: "",
    originId: "",
    datetimeCreated: "",
    state: "",
    price: {
      value: 0,
      currency: ""
    },
    customer: {
      name: "",
      email: "",
      phone: ""
    }
  },
  items: [],
  formTypes: [],
  claimReasons: [],
  orderAfterDeadline: false,
  bankAccount: false
};

export const rmaCaseTypeImages: RmaCaseTypeImages = {
  CLAIM: {
    imageName: "return-gray"
  },
  CHANGE: {
    imageName: "change-gray"
  },
  RETURN: {
    imageName: "return-gray"
  }
};

export const rmaCaseStateImages: RmaCaseStateImages = {
  NEW: {
    imageName: "check-grey"
  },
  OPENED: {
    imageName: "check-grey"
  },
  APPROVING: {
    imageName: "approving"
  },
  COMPLETED: {
    imageName: "goods-accepted"
  },
  CANCELLED: {
    imageName: "cancelled"
  },
  CLOSED: {
    imageName: "cancelled"
  }
};
