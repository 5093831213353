import { createI18n, I18n } from "vue-i18n";
import { VueCookieNext } from "vue-cookie-next";
import { axios } from "@/services/auth";

async function loadTranslations(
  i18n: I18n<{}, {}, {}, false>,
  langCode: string
): Promise<string> {
  const apiUrl = VueCookieNext.getCookie("apiUrl");
  const response = await axios.get(
    `${apiUrl}/frontend-api/translations/${langCode}`
  );

  if (response?.data) {
    i18n.global.setLocaleMessage(langCode, response.data);

    return langCode;
  }

  return "";
}

export async function setI18nLanguage(langCode: string) {
  const fallbackLangCode = "sk";

  const i18n = createI18n({
    legacy: false,
    fallback: fallbackLangCode
  }) as I18n<{}, {}, {}, false>;

  const langCodeFinal =
    (await loadTranslations(i18n, langCode)) ||
    (await loadTranslations(i18n, fallbackLangCode));

  i18n.global.locale.value = langCodeFinal;
  document.querySelector("html")?.setAttribute("lang", langCodeFinal);

  return i18n;
}
