
import { defineComponent, PropType, inject } from "vue";
import { useI18n } from "vue-i18n";
import { RmaCaseForOrder } from "@/types";
import { redirectTo } from "@/utils";
import { MarketConfig } from "@/types";

export default defineComponent({
  props: {
    rmas: {
      type: Object as PropType<Array<RmaCaseForOrder> | null>
    }
  },
  setup() {
    const { t, locale: lang } = useI18n();

    const { change } = inject("marketConfig") as MarketConfig;

    return {
      t,
      change,
      lang,
      redirectTo
    };
  }
});
