import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "nav" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", { href: _ctx.marketUrl }, [
      _createElementVNode("img", { src: _ctx.logoUrl }, null, 8, _hoisted_3)
    ], 8, _hoisted_2)
  ]))
}