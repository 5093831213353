import axios from "axios";
import { VueCookieNext } from "vue-cookie-next";
import mitt from "mitt";

const emitter = mitt();

async function getAuthToken(apiKey: any): Promise<string> {
  const apiUrl = VueCookieNext.getCookie("apiUrl");
  const response = await axios.post(`${apiUrl}/authentication_token`, apiKey);
  VueCookieNext.setCookie("authToken", response.data.token, { expire: "1h" });
  return response.data.token;
}

function logoutUser(
  logoutType: "logout-by-user" | "logout",
  showAlert: boolean
) {
  VueCookieNext.removeCookie("isAuthenticated");
  VueCookieNext.removeCookie("authToken");
  VueCookieNext.removeCookie("orderId");
  VueCookieNext.removeCookie("email");
  if (showAlert) emitter.emit("showAlert", { type: logoutType });
}

export { getAuthToken, logoutUser, emitter, axios };
