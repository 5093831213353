
import { computed, ComputedRef, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { OrderDetail } from "@/types";
import { redirectTo } from "@/utils";
import { logoutUser } from "@/services/auth";

import Button from "@/components/Button.vue";

interface NewRrmaRequestRedirect {
  newRmaDisabledReason: ComputedRef<"NO_ITEMS_LEFT" | "AFTER_DEADLINE" | null>;
  newRmaDisabled: ComputedRef<boolean>;
  redirectToIfNotDisabled: Function;
}

function newRrmaRequestRedirect(
  orderSearchResult: OrderDetail
): NewRrmaRequestRedirect {
  const newRmaDisabled = computed(() => !orderSearchResult.newRma.valid);

  const newRmaDisabledReason = computed(() =>
    orderSearchResult.newRma.reasonId
      ? orderSearchResult.newRma.reasonId === 4
        ? "AFTER_DEADLINE"
        : "NO_ITEMS_LEFT"
      : null
  );

  const redirectToIfNotDisabled = (
    componentName: string,
    parameters: any
  ): void => {
    if (!newRmaDisabled.value) {
      redirectTo(componentName, parameters);
    }
  };

  return {
    newRmaDisabledReason,
    newRmaDisabled,
    redirectToIfNotDisabled
  };
}

export default defineComponent({
  components: {
    Button
  },
  props: {
    orderSearchResult: {
      type: Object as PropType<OrderDetail>,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n();

    const {
      newRmaDisabledReason,
      newRmaDisabled,
      redirectToIfNotDisabled
    } = newRrmaRequestRedirect(props.orderSearchResult);

    return {
      t,
      newRmaDisabled,
      newRmaDisabledReason,
      redirectToIfNotDisabled,
      logoutUser
    };
  }
});
