import { AlertData } from "@/types";

export function getAlertData(type: string): AlertData {
  switch (type) {
    case "logout":
      return {
        title: "ALERTS.LOGOUT_TITLE",
        text: "ALERTS.LOGOUT_TEXT",
        btnText: "ALERTS.LOGOUT_BUTTON",
        btnAction: "closeNRoute",
        imgName: "hand"
      };
    case "logout-by-user":
      return {
        title: "ALERTS.LOGOUTBYUSER_TITLE",
        text: "ALERTS.LOGOUTBYUSER_TEXT",
        btnText: "ALERTS.LOGOUT_BUTTON",
        btnAction: "closeNRoute",
        imgName: "hand"
      };
    case "leave":
      return {
        title: "ALERTS.LEAVE_TITLE",
        text: "ALERTS.LEAVE_TEXT",
        btnText: "ALERTS.LEAVE_BUTTON",
        btnAction: "closeNRoute",
        hasCloseBtn: true,
        xBtnAction: "closeBtn",
        emitsWithEmitter: true,
        imgName: "hand"
      };
    case "error":
      return {
        title: "ERRORS.ERROR_TITLE",
        text: "ERRORS.ERROR_TEXT",
        hasCloseBtn: true,
        xBtnAction: "closeNRoute",
        imgName: "warning"
      };
    case "warning":
      return {
        title: "ERRORS.ERROR_TITLE",
        text: "ERRORS.ERROR_WARNING_TEXT",
        hasCloseBtn: true,
        xBtnAction: "closeBtn",
        imgName: "warning"
      };
    case "wrongInput":
      return {
        title: "ERRORS.ERROR_TITLE",
        text: "ERRORS.ERROR_WRONG_INPUT_TEXT",
        hasCloseBtn: true,
        xBtnAction: "closeBtn",
        imgName: "warning"
      };
    case "donePopup":
      return {
        title: "DONE.CANNOT_DOWNLOAD.TEXT",
        btnText: "DONE.CANNOT_DOWNLOAD.CONFIRM",
        btnAction: "closeBtn",
        hasCloseBtn: true,
        xBtnAction: "closeBtn",
        imgName: "hand"
      };
    default:
      return {
        title: "ALERTS.LOGOUT_TITLE",
        text: "ALERTS.LOGOUT_TEXT",
        btnText: "ALERTS.LOGOUT_BUTTON",
        btnAction: "closeNRoute",
        imgName: "hand"
      };
  }
}
