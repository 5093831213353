import App from "./App.vue";
import { AxiosResponse } from "axios";
import { createApp } from "vue";
import { router } from "./router/router";
import { I18n } from "vue-i18n";
import { setI18nLanguage } from "@/translations/translations";
import { axios, emitter } from "@/services/auth";
import setApolloClient from "@/services/apollo-client";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { VueCookieNext } from "vue-cookie-next";
import { initSentry } from "@/sentry";

VueCookieNext.config({ expire: "1d" });

(async () => {
  const config = (await axios.get("/config.json")) as AxiosResponse<any>;

  const apiUrl = process.env.VUE_APP_API_URL as string;
  const apiKey = config.data.apiKey;
  VueCookieNext.setCookie("apiKey", JSON.stringify(apiKey));
  VueCookieNext.setCookie("apiUrl", apiUrl);
  const defaultClient = setApolloClient(apiUrl, apiKey);

  const langCode = config.data.langCode as string;
  const i18n = (await setI18nLanguage(langCode)) as I18n<{}, {}, {}, false>;

  const app = createApp(App)
    .provide(DefaultApolloClient, defaultClient)
    .provide("cookies", VueCookieNext)
    .provide("emitter", emitter)
    .provide("marketConfig", config.data.marketConfig)
    .use(router)
    .use(VueCookieNext)
    .use(i18n);

  initSentry(app);
  app.mount("#app");
})();
