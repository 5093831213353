
import { defineComponent, inject } from "vue";
import { useI18n } from "vue-i18n";
import Button from "@/components/Button.vue";
import { useRouter } from "vue-router";
import { logoutUser } from "@/services/auth";

export default defineComponent({
  components: {
    Button
  },
  props: {
    type: {
      type: String,
      default: ""
    }
  },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const cookies = inject("cookies") as any;

    const logoutNRouteToHomepage = () => {
      const email = cookies.getCookie("email");
      const orderId = cookies.getCookie("orderId");
      logoutUser("logout-by-user", false);

      router.push({
        path: "/login/order",
        query: { email: email, orderId: orderId }
      });
    };

    return { t, logoutNRouteToHomepage };
  }
});
