import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { id: "notfound" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.type === 'error')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.t("ERRORS.ERROR_TITLE")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.t("ERRORS.ERROR_TEXT")), 1),
            _createVNode(_component_Button, {
              text: _ctx.t('ERRORS.BACK_TO_HOMEPAGE'),
              icon: "home",
              onClick: _ctx.logoutNRouteToHomepage
            }, null, 8, ["text", "onClick"])
          ]))
        : (_ctx.type === 'noresult')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default"),
              _createVNode(_component_Button, {
                text: _ctx.t('ERRORS.BACK_TO_HOMEPAGE'),
                icon: "home",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/')))
              }, null, 8, ["text"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.t("ERRORS.NOT_FOUND_TITLE")), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.t("ERRORS.NOT_FOUND")), 1),
              _createVNode(_component_Button, {
                text: _ctx.t('ERRORS.BACK_TO_HOMEPAGE'),
                icon: "home",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/')))
              }, null, 8, ["text"])
            ]))
    ])
  ]))
}