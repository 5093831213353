
import { defineComponent, inject, Ref } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import orderSearchQuery from "@/graphql/orderSearch.query.gql";
import { OrderDetail, RmaCaseForOrder } from "@/types";
import {
  rmaCaseTypeImages,
  rmaCaseStateImages,
  defaultOrderData
} from "@/constants";
import { useI18n } from "vue-i18n";
import { replaceWaitingReplyState } from "@/utils";

import OrderHeader from "@/views/order/OrderHeader.vue";
import OrderedGoods from "@/views/order/OrderedGoods.vue";
import TicketsTableWeb from "@/views/order/TicketsTableWeb.vue";
import TicketsTableMobile from "@/views/order/TicketsTableMobile.vue";
import OrderInfo from "@/views/order/OrderInfo.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import NotFound from "@/components/NotFound.vue";

interface HandleQueryOrderSearch {
  orderSearchResult: Readonly<Ref<Readonly<OrderDetail>>>;
  loading: Ref<boolean>;
  error: Ref<null|Error>;
}

function handleQueryOrderSearch(): HandleQueryOrderSearch {
  const cookies = inject("cookies") as any;
  const variables = {
    email: cookies.getCookie("email"),
    orderId: cookies.getCookie("orderId")
  };

  const { result, loading, error, onResult } = useQuery(
    orderSearchQuery,
    variables,
    { fetchPolicy: "network-only" }
  );

  const orderSearchResult = useResult(result, defaultOrderData, data => {
    if (data.orderSearch.rmas) {
      return {
        ...data.orderSearch,
        rmas: data.orderSearch.rmas.map((rma: RmaCaseForOrder) => {
          return {
            ...rma,
            rmaCase: {
              ...rma.rmaCase,
              type: rma.rmaCase.type.toUpperCase(),
              state: replaceWaitingReplyState(rma.rmaCase.state)
            }
          };
        })
      };
    } else {
      return data.orderSearch;
    }
  }) as Readonly<Ref<Readonly<OrderDetail>>>;

  onResult(() => {
    cookies.setCookie("token", orderSearchResult.value.token, {
      expire: 0
    });
  });

  return {
    orderSearchResult,
    loading,
    error
  };
}

export default defineComponent({
  components: {
    OrderHeader,
    OrderedGoods,
    TicketsTableWeb,
    TicketsTableMobile,
    OrderInfo,
    ClipLoader,
    NotFound
  },
  setup() {
    const { t } = useI18n();

    const { orderSearchResult, loading, error } = handleQueryOrderSearch();

    return {
      loading,
      error,
      orderSearchResult,
      rmaCaseTypeImages,
      rmaCaseStateImages,
      t
    };
  }
});
