import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5286bd26"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  id: "order-detail"
}
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "goods-info" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clip_loader = _resolveComponent("clip-loader")!
  const _component_NotFound = _resolveComponent("NotFound")!
  const _component_OrderHeader = _resolveComponent("OrderHeader")!
  const _component_OrderedGoods = _resolveComponent("OrderedGoods")!
  const _component_OrderInfo = _resolveComponent("OrderInfo")!
  const _component_TicketsTableWeb = _resolveComponent("TicketsTableWeb")!
  const _component_TicketsTableMobile = _resolveComponent("TicketsTableMobile")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_clip_loader, {
          color: "#777777",
          size: "100px"
        })
      ]))
    : (_ctx.error || !_ctx.orderSearchResult.result)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_NotFound, { type: "error" })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_OrderHeader, { orderSearchResult: _ctx.orderSearchResult }, null, 8, ["orderSearchResult"]),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.orderSearchResult.items)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_OrderedGoods, {
                      orderItems: _ctx.orderSearchResult.items
                    }, null, 8, ["orderItems"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_OrderInfo, {
                order: _ctx.orderSearchResult.order
              }, null, 8, ["order"])
            ]),
            _createVNode(_component_TicketsTableWeb, {
              rmas: _ctx.orderSearchResult.rmas
            }, null, 8, ["rmas"]),
            _createVNode(_component_TicketsTableMobile, {
              rmas: _ctx.orderSearchResult.rmas
            }, null, 8, ["rmas"])
          ])
        ]))
}