
import { defineComponent, inject } from "vue";
import { useI18n } from "vue-i18n";
import Button from "@/components/Button.vue";
import CloseBtn from "@/components/CloseBtn.vue";
import { getAlertData } from "@/utils";

export default defineComponent({
  components: {
    Button,
    CloseBtn
  },
  emits: ["closeBtn", "closeNRoute"],
  props: {
    type: {
      type: String,
      default: "logout"
    },
    rmaCode: {
      type: String,
      default: ""
    }
  },

  setup(props) {
    const { t } = useI18n();
    const emitter = inject("emitter") as any;
    const emitWithEmmiter = (event: string) => {
      emitter.emit(event);
    };

    const {
      title,
      text,
      btnText,
      btnAction,
      hasCloseBtn,
      xBtnAction,
      emitsWithEmitter,
      imgName
    } = getAlertData(props.type);

    return {
      t,
      emitWithEmmiter,
      text,
      title,
      btnText,
      btnAction,
      hasCloseBtn,
      xBtnAction,
      emitsWithEmitter,
      imgName
    };
  }
});
