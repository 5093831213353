import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Teleport as _Teleport } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "alert_modal"
}
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_CustomAlert = _resolveComponent("CustomAlert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TheHeader),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view),
      (_ctx.isAlertVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "slide-fade" }, {
        default: _withCtx(() => [
          (_ctx.isAlertVisible)
            ? (_openBlock(), _createBlock(_component_CustomAlert, {
                key: 0,
                onCloseBtn: _ctx.closeAlert,
                onCloseNRoute: _ctx.closeAlertAndRoute,
                type: _ctx.type,
                rmaCode: _ctx.rmaCode
              }, null, 8, ["onCloseBtn", "onCloseNRoute", "type", "rmaCode"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    (_openBlock(), _createBlock(_Teleport, { to: "head" }, [
      _createElementVNode("link", {
        rel: "icon",
        href: _ctx.favicon
      }, null, 8, _hoisted_4),
      _createElementVNode("title", null, _toDisplayString(_ctx.title), 1)
    ]))
  ]))
}