import { createRouter, createWebHistory } from "vue-router";
import OrderDetail from "@/views/order/OrderDetail.vue";
import NotFound from "@/components/NotFound.vue";
import { VueCookieNext } from "vue-cookie-next";
import { logoutUser } from "@/services/auth";
import { isUrlParametersValid } from "@/utils";

const ifAuthenticated = (to: any, _from: any, next: any) => {
  if (VueCookieNext.getCookie("isAuthenticated") === "true") {
    next();
    return;
  }
  if (to.path.includes("ticket-detail")) {
    next("/login/rma");
    return;
  }
  next("/login/order");
};

const ifNotAuthenticated = (to: any, _from: any, next: any) => {
  if (VueCookieNext.getCookie("isAuthenticated") !== "true") {
    next();
    return;
  } else if (to.query && isUrlParametersValid(to.query)) {
    logoutUser("logout", false);
    next();
    return;
  }
  next("/");
};

const loginParamExists = (to: any, _from: any, next: any) => {
  if (to.path === "/login/rma" || to.path === "/login/order") {
    next();
    return;
  }
  next("/login/order");
};

const canEnterNewTicket = (_to: any, from: any, next: any) => {
  if (from.path.includes("done")) {
    next("/");
    return;
  }
  next();
};

const canEnterDonePage = (_to: any, _from: any, next: any) => {
  if (VueCookieNext.getCookie("donePageAccessible") === "true") {
    next();
    return;
  }
  next("/");
};

const routes = [
  {
    path: "/",
    name: "OrderDetail",
    component: OrderDetail,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/login/:param",
    name: "LoginPage",
    component: () => import("@/views/login/LoginPage.vue"),
    beforeEnter: [ifNotAuthenticated, loginParamExists]
  },
  {
    path: "/new-ticket/:oid",
    name: "NewTicket",
    component: () => import("@/views/tickets/new_ticket/NewTicket.vue"),
    beforeEnter: [ifAuthenticated, canEnterNewTicket]
  },
  {
    path: "/ticket-detail/:tid",
    name: "TicketDetail",
    component: () => import("@/views/tickets/ticket_detail/TicketDetail.vue"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/done",
    name: "Done",
    component: () => import("@/views/done/Done.vue"),
    beforeEnter: [ifAuthenticated, canEnterDonePage]
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound
  }
];

export const router = createRouter({
  history: createWebHistory(),
  routes
});
