
import { defineComponent, inject } from "vue";
import { MarketConfig } from "@/types";

export default defineComponent({
  setup() {
    const { logoUrl, marketUrl } = inject("marketConfig") as MarketConfig;
    return {
      logoUrl,
      marketUrl
    };
  }
});
