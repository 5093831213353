
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { Order } from "@/types";

export default defineComponent({
  props: {
    order: {
      type: Object as PropType<Array<Order>>,
      required: true
    }
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  }
});
