import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ef0b4ecc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "icon"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 3,
  class: "tooltiptext"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_clip_loader = _resolveComponent("clip-loader")!

  return (_openBlock(), _createElementBlock("button", {
    ref: "button",
    class: _normalizeClass(["custom-button", { disabled: _ctx.disabled, question: _ctx.optClass === 'question' }]),
    disabled: _ctx.loadingState
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.getIconUrl(),
            alt: ""
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.loadingState)
      ? (_openBlock(), _createBlock(_component_clip_loader, {
          key: 1,
          class: "loader",
          color: "white",
          size: "25px"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.text), 1)),
    (_ctx.tooltip && _ctx.disabled)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.t(`ORDER.NEW_TICKET_INVALID.${_ctx.disabledReason}`)), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}