import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-899b34fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "goods-table-web",
  border: "0",
  cellpadding: "0",
  cellspacing: "0",
  "data-cy": "rma-web-table"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "data-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "type" }
const _hoisted_6 = { class: "data-wrapper" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "images-row" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "images"
}
const _hoisted_11 = {
  key: 1,
  class: "images"
}
const _hoisted_12 = { class: "img-wrapper" }
const _hoisted_13 = { class: "img-wrapper" }
const _hoisted_14 = { class: "img-wrapper" }
const _hoisted_15 = { class: "number" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "altText" }
const _hoisted_18 = {
  key: 0,
  colspan: "5"
}
const _hoisted_19 = {
  key: 1,
  colspan: "5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("thead", null, [
      _createElementVNode("tr", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeaders, (column, index) => {
          return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(_ctx.t(`ORDER.TABLE_HEADERS.${column}`)), 1))
        }), 128))
      ])
    ]),
    (_ctx.rmas && _ctx.rmas.length !== 0)
      ? (_openBlock(), _createElementBlock("tbody", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rmas, (ticket) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: ticket.rmaCase.id
            }, [
              _createElementVNode("td", null, _toDisplayString(ticket.rmaCase.code), 1),
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("img", {
                    src: 
                _ctx.getIconUrl(_ctx.rmaCaseTypeImages[ticket.rmaCase.type].imageName)
              
                  }, null, 8, _hoisted_4),
                  _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t(`COMMON.RETURN_TYPES.${ticket.rmaCase.type}.DESCRIPTION`)), 1)
                ])
              ]),
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("img", {
                    src: 
                _ctx.getIconUrl(_ctx.rmaCaseStateImages[ticket.rmaCase.state].imageName)
              
                  }, null, 8, _hoisted_7),
                  _createElementVNode("p", {
                    class: _normalizeClass(["ticket-status", ticket.rmaCase.state.toLowerCase()])
                  }, _toDisplayString(_ctx.t(`COMMON.RMA_STATES.${ticket.rmaCase.state}`)), 3)
                ])
              ]),
              _createElementVNode("td", _hoisted_8, [
                (ticket.items !== null)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (ticket.items.length < 4)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ticket.items, (ticketItem) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: ticketItem.id,
                                class: "img-wrapper"
                              }, [
                                _createElementVNode("div", {
                                  class: "goods",
                                  style: _normalizeStyle(
                    'background-image: url(' +
                      ticketItem.imageUrl +
                      '), url(' +
                      require(`@/assets/images/image-not-found-${_ctx.lang}.jpg`) +
                      ')'
                  )
                                }, null, 4)
                              ]))
                            }), 128))
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("div", {
                                class: "goods",
                                style: _normalizeStyle(
                    'background-image: url(' +
                      ticket.items[0].imageUrl +
                      '), url(' +
                      require(`@/assets/images/image-not-found-${_ctx.lang}.jpg`) +
                      ')'
                  )
                              }, null, 4)
                            ]),
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", {
                                class: "goods",
                                style: _normalizeStyle(
                    'background-image: url(' +
                      ticket.items[1].imageUrl +
                      '), url(' +
                      require(`@/assets/images/image-not-found-${_ctx.lang}.jpg`) +
                      ')'
                  )
                              }, null, 4)
                            ]),
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", {
                                class: "goods",
                                style: _normalizeStyle(
                    'background-image: url(' +
                      ticket.items[2].imageUrl +
                      '), url(' +
                      require(`@/assets/images/image-not-found-${_ctx.lang}.jpg`) +
                      ')'
                  )
                              }, null, 4)
                            ]),
                            _createElementVNode("div", _hoisted_15, "+" + _toDisplayString(ticket.items.length - 3), 1)
                          ]))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("td", null, [
                _createVNode(_component_Button, {
                  text: _ctx.t('COMMON.DETAILS'),
                  onClick: ($event: any) => (_ctx.redirectTo('TicketDetail', { tid: ticket.rmaCase.id })),
                  "data-cy": "to-ticket-detail"
                }, null, 8, ["text", "onClick"])
              ])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("tbody", _hoisted_16, [
          _createElementVNode("tr", _hoisted_17, [
            (_ctx.change)
              ? (_openBlock(), _createElementBlock("td", _hoisted_18, _toDisplayString(_ctx.t("ORDER.EMPTY_LISTING_CHANGE")), 1))
              : (_openBlock(), _createElementBlock("td", _hoisted_19, _toDisplayString(_ctx.t("ORDER.EMPTY_LISTING")), 1))
          ])
        ]))
  ]))
}